import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../templates/PageTemplate";
import { auth, db } from "../config/firebase";
import { Button } from "@mui/joy";
import qs from "qs";
import axios from "axios";
import { toast } from "react-toastify";
import { PayPalButtons } from "@paypal/react-paypal-js";

export default function PaymentSuccessfull({ navigation }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <PageTemplate
      title={""}
      content={
        <div className="flex flex-col items-center justify-center">
          <img
            src={require("../assets/img/trophee.png")}
            resizeMode="contain"
            className="w-24 h-24"
          />
          <span className="text-base text-center text-black mt-10 mb-2">
           Paiment éffectué
          </span>
          <span className="text-sm text-center text-gray-700">
            Votre paiement a été validé avec succès
          </span>

          <div className="mt-4 flex items-center justify-center">
          <Button onClick={()=>navigate("/mes-recherches")} variant="solid">
            Retour
          </Button>
        </div>
        </div>
      }
    />
  );
}
