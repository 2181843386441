import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import { toast } from "react-toastify";
import { Button, Input } from "@mui/joy";
import { auth, db } from "../config/firebase";
import PageTemplate from "../templates/PageTemplate";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

export default function PersonalInformations() {
  const { t } = useTranslation(); // Initialize useTranslation hook
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [buttonisLoading, setButtonIsLoading] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const docRef = doc(db, "users", auth?.currentUser?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = { id: docSnap.id, ...docSnap.data() };
          setData(userData);
          setFirstName(userData.firstName);
          setLastName(userData.lastName);
          setEmail(userData.email);
        }
      } catch (error) {
        alert(
          `Nous n'arrivons pas à récupérer vos données, veuillez contacter le support: ${error}`
        );
        return;
      } finally {
        setIsLoading(false);
      }
    };

    getUserInfo();
  }, []);

  const validate = async () => {
    setButtonIsLoading(true);
    try {
      await updateDoc(doc(db, "users", auth.currentUser?.uid), {
        firstName: firstName,
        lastName: lastName,
      });
      await updateProfile(auth.currentUser, {
        displayName: firstName + " " + lastName,
      });

      toast.success(t('update_success')); // Use translation here
    } catch (error) {
      toast.error(t('update_error', { error: error.message })); // Use translation here
    } finally {
      setButtonIsLoading(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <PageTemplate
      title={t('personal_info_title')} // Use translation here
      content={
        <form className="p-5 bg-gray-200 max-w-md rounded-sm border border-gray-300">
          <div className="mt-2">
            <span className="text-base">{t('personal_info_last_name')}</span> {/* Use translation here */}
            <Input
              className="w-full"
              value={lastName}
              variant="outlined"
              placeholder={`${t('personal_info_last_name')} *`} // Use translation here
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <span className="text-base">{t('personal_info_first_name')}</span> {/* Use translation here */}
            <Input
              className="w-full"
              value={firstName}
              variant="outlined"
              placeholder={`${t('personal_info_first_name')} *`} // Use translation here
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <span className="text-base">{t('personal_info_email')}</span> {/* Use translation here */}
            <Input
              disabled
              className="w-full"
              value={email}
              variant="outlined"
              placeholder={`${t('personal_info_email')} *`} // Use translation here
            />
          </div>
          <div className="mt-4">
            <Button
              style={{ width: "100%" }}
              onClick={validate}
              loading={buttonisLoading}
              variant="solid"
            >
              {t('update_button')} {/* Use translation here */}
            </Button>
          </div>
        </form>
      }
    />
  );
}
