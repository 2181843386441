import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../templates/PageTemplate";
import { Button, Input, Textarea } from "@mui/joy";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  const [cookies, setCookies] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!message || !firstName || !lastName || !email) {
      toast.info(t("form_empty"));
      return;
    }

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "template_bxdnc99",
        {
          from_name: lastName + " " + firstName,
          logo_url: "https://play-lh.googleusercontent.com/i9ksRKY_fmYyDGW5qkUqCgmTJ-j_8GoQDQMkFK-83ul1gtgECXKzWcikJU-u_1gb4fM=w240-h480-rw",
          email: email,
          message: message,
        },
        "UA0YjN4TrcLbrt2_S"
      )
      .then(() => {
        toast.info(t("message_sent"));
        navigate("/");
      })
      .catch((err) => {
        toast.info(`${t("error")}: ${err}`);
        alert(`${t("error")}: ${JSON.stringify(err)}`);
      });
  };

  return (
    <PageTemplate
      content={
        <div>
          <section className="min-h-screen">
            <form
              onSubmit={handleSubmit}
              className="w-full md:w-7/12 mx-auto bg-white rounded-xl p-3 md:p-10"
            >
              <div className="flex flex-col">
                <h1 className="text-4xl font-bold">Nous contacter</h1>
                <div className="md:flex items-center gap-5">
                  <div className="w-full md:w-1/2 mt-7 mb-3">
                    <Input
                      className="w-full"
                      placeholder={t("placeholder_last_name")}
                      value={lastName}
                      variant="outlined"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="w-full md:w-1/2 md:mt-7 mb-3">
                    <Input
                      className="w-full"
                      placeholder={t("placeholder_first_name")}
                      value={firstName}
                      variant="outlined"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <Input
                    className="w-full"
                    placeholder={t("placeholder_email")}
                    value={email}
                    variant="outlined"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div className="mb-5">
                  <Textarea
                    className="w-full"
                    value={message}
                    variant="outlined"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={t("placeholder_message")}
                    style={{ display: "block", height: 250 }}
                  />
                </div>

                <Button type="submit" size="lg" variant={"solid"}>
                  {t("button_send_message")}
                </Button>

                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="mt-10 px-5 text-xs text-center font-light hover:underline text-gray-400"
                >
                  {t("consent_text")}
                </button>
              </div>
            </form>
          </section>
        </div>
      }
    />
  );
}
