import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../templates/PageTemplate";
import { auth, db } from "../config/firebase";
import { useTranslation } from "react-i18next";

export default function Notifications({ navigation }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!auth.currentUser) {
      setIsLoading(false);
      return;
    }
    getNotifs();
  }, []);

  async function getNotifs() {
    try {
      const q = query(
        collection(db, "notifications"),
        where("userId", "==", auth.currentUser.uid),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      const dataArr = [];
      querySnapshot.forEach(async (res) => {
        dataArr.push({
          id: res.id,
          ...res.data(),
        });
      });
      setData(dataArr);
    } catch (error) {
      console.log(`${error}`);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateData(itemId, isViewed) {
    if (isViewed) {
      return;
    }
    const docRef = doc(db, "notifications", itemId);
    await updateDoc(docRef, {
      isViewed: true,
    });
    getNotifs();
  }

  if (isLoading) {
    return null;
  }

  const Card = ({ item }) => (
    <div
      activeOpacity={0.7}
      onClick={() => {
        updateData(item?.id, item?.isViewed);
      }}
      className="cursor-pointer w-full px-4 py-2 mb-2 rounded-md bg-white border border-gray-100"
    >
      <h3 className="text-base text-black mb-2">
        {i18n?.language === "en" && item?.title_en
          ? item?.title_en
          : item?.title}
      </h3>
      <div className="flex-row items-center gap-5 w-full">
        {!item?.isViewed && (
          <div className="w-2 h-2 bg-red-600 rounded-full"></div>
        )}
        <h4 className="text-sm text-gray-700">
        {i18n?.language === "en" && item?.text_en
          ? item?.text_en
          : item?.text}
        </h4>
      </div>
      <h4 className="text-xs mt-4 self-end text-gray-400">
        {moment(item?.createdAt).format("DD/MM/YYYY à h:mm a")}
      </h4>
    </div>
  );

  if (data.length < 1) {
    return (
      <PageTemplate
        title={"Notifications"}
        content={
          <div className="flex flex-col items-center justify-center">
            <img
              src={require("../assets/img/notification.png")}
              resizeMode="contain"
              className="w-24 h-24"
            />
            <span className="text-base text-center text-black mt-10 mb-2">
              Aucune notification pour le moment
            </span>
            <span className="text-sm text-center text-gray-700">
              Vous serez notifié(e) quand il y'aura du nouveau
            </span>
          </div>
        }
      />
    );
  }

  return (
    <PageTemplate
      title={"Notifications"}
      content={
        <div className="">
          {data.map((item, i) => (
            <Card item={item} />
          ))}
        </div>
      }
    />
  );
}
