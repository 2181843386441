import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import CountrySelect from "../components/CountrySelect";
import PageTemplate from "../templates/PageTemplate";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../config/firebase";
import Select from "react-select";
import { Button, Input } from "@mui/joy";
import moment from "moment";
import { toast } from "react-toastify";
import { cleanString } from "../utils/allFunctions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PublishACar() {
  const [cats, setCats] = useState([]);
  const [country, setCountry] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [assurance, setAssurance] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [buttonisLoading, setButtonIsLoading] = useState(false);

  const navigate = useNavigate();
  const { t,i18n } = useTranslation();

  useEffect(() => {
    getCategories();
  }, []);

  const customSort = (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    // Mettre "voiture" en premier
    if (nameA.startsWith("voiture") && !nameB.startsWith("voiture")) {
      return -1;
    }
    if (!nameA.startsWith("voiture") && nameB.startsWith("voiture")) {
      return 1;
    }

    // Trier par ordre alphabétique
    return nameA.localeCompare(nameB);
  };

  async function getCategories() {
    const q = query(collection(db, "categories"));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      dataArr.push({
        id: res.id,
        ...res.data(),
      });
    });

    dataArr.sort(customSort);
    setCats(dataArr);
    setIsLoading(false);
  }

  const options = cats?.map((cat) => ({
    value: cat.name,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          className="rounded-sm"
          src={cat.image}
          alt={cat.name}
          style={{ width: "20px", marginRight: "8px" }}
        />
        {i18n?.language === "en"
          ? cat.name_en.charAt(0).toUpperCase() + cat.name_en.slice(1)
          : cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
      </div>
    ),
  }));

  const handleChangeCountry = (selected) => {
    setCountry(selected.value);
  };

  const handleChange = (selected) => {
    setType(selected.value);
  };

  function enleverEspaces(chaine) {
    return chaine.replace(/\s/g, "");
  }

  const sendMailToSearcher = async () => {
    try {
      const response = await axios.post(
        "https://api.sendgrid.com/v3/mail/send",
        {
          personalizations: [
            {
              to: [
                {
                  email: `${auth?.currentUser?.email}`,
                },
              ],
              dynamic_template_data: {
                first_name: auth?.currentUser?.displayName,
              },
            },
          ],
          from: {
            email: `${process.env.REACT_APP_SENDGRID_FROM}`,
          },
          template_id: enleverEspaces("d-d8a99978650a4a6e9149b3cd7fd445de"),
        },
        {
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Email sent successfully:", response.data);
    } catch (error) {
      if (error.response) {
        console.log("Error response data:", error.response.data);
        console.log("Error response status:", error.response.status);
        console.log("Error response headers:", error.response.headers);
      } else if (error.request) {
        console.log("Error request:", error.request);
      } else {
        console.log("Error message:", error.message);
      }
      console.log("Error config:", error.config);
    }
  };

  async function validate() {
    setButtonIsLoading(true);
    if (!assurance || !vehicleId || !country || !type) {
      toast.info(`Des champs sont manquants`);
      setButtonIsLoading(false);
      return;
    }
    try {
      const q = query(
        collection(db, "searchs"),
        where("plate", "==", cleanString(vehicleId.toUpperCase())),
        where("userId", "==", null),
        where("status", "==", "progress")
      );
      const querySnapshot = await getDocs(q);
      const dataArr = [];
      if (querySnapshot.size > 0) {
        querySnapshot.forEach((res) => {
          dataArr.push({ id: res.id, ...res.data() });
        });
        const findedSearchDemand = dataArr[0];

        await updateDoc(doc(db, "searchs", findedSearchDemand.id), {
          country: country,
          type: type,
          assurance: assurance,
          status: "ended",
          userId: auth?.currentUser.uid,
          finderPaymentStatus: "unpaid",
          createdAt: moment().format(),
        });

        toast.success(
          "Votre véhicule a été retrouvé, RDV dans l'onglet Mes recherches pour pouvoir consulter les photos et la dernière position où il a été aperçu"
        );
        navigate("/");

        sendMailToSearcher();
      } else {
        await addDoc(collection(db, "searchs"), {
          country: country,
          assurance: assurance,
          plate: cleanString(vehicleId),
          type: type,
          userId: auth?.currentUser?.uid,
          finderId: null,
          status: "progress",
          price: "",
          finderPrice: "",
          paymentStatus: "unpaid",
          finderPaymentStatus: "unpaid",
          matchAnimation: false,
          createdAt: moment().format(),
        });

        toast.success("Votre demande a bien été prise en compte");
        navigate("/");
      }
    } catch (error) {
      toast.error(`${error}`);
      return;
    } finally {
      setButtonIsLoading(false);
    }
  }

  if (isLoading) {
    return null;
  }

  return (
    <PageTemplate
      title={t("page_title_publish_vehicle")}
      content={
        <form className="p-5 bg-gray-200 max-w-md rounded-sm border border-gray-300">
          <div>
            <span className="text-base">{t("select_country")}</span>
            <CountrySelect onChange={handleChangeCountry} />
          </div>
          <div className="mt-2">
            <span className="text-base">{t("select_vehicle_type")}</span>
            <Select onChange={handleChange} options={options} />
          </div>
          <div className="mt-2">
            <span className="text-base">{t("license_plate")}</span>
            <Input
              className="w-full"
              value={vehicleId}
              placeholder={t("license_plate")}
              variant="outlined"
              onChange={(e) => {
                setVehicleId(e.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <span className="text-base">{t("insurance")}</span>
            <Input
              className="w-full"
              value={assurance}
              placeholder={t("insurance")}
              variant="outlined"
              onChange={(e) => {
                setAssurance(e.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <Button
              style={{ width: "100%" }}
              onClick={validate}
              loading={buttonisLoading}
              variant="solid"
            >
              {t("search_button")}
            </Button>
          </div>
        </form>
      }
    />
  );
}
