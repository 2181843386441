import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './localization/en.json';
import fr from './localization/fr.json';

// Détection de la langue par défaut
const userLang = localStorage.getItem('i18nextLng') || navigator.language || navigator.userLanguage;

// Si c'est la première fois que l'utilisateur arrive sur le site
let initialLanguage;
if (!localStorage.getItem('i18nextLng')) {
  initialLanguage = userLang.startsWith('fr') ? 'fr' : 'en';
  localStorage.setItem('i18nextLng', initialLanguage);
} else {
  initialLanguage = userLang;
}

// Configuration de i18next
i18n
  .use(initReactI18next) // Passe l'instance i18next à react-i18next.
  .init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    lng: initialLanguage, // Utiliser la langue initiale déterminée
    fallbackLng: 'en', // Langue de secours si la langue choisie n'est pas disponible
    interpolation: {
      escapeValue: false, // Pas nécessaire pour React, car il échappe déjà les valeurs.
    },
  });

export default i18n;
