import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../../config/firebase";
import Input from "@mui/joy/Input";
import { Button } from "@mui/joy";
import HeaderAuth from "../../components/Headers/HeaderAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailRegex } from "../../utils/allFunctions";
import { collection, query, where } from "firebase/firestore";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize useTranslation hook
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validate = async () => {
    if (!email) {
      toast.info(t('error_empty_email')); // Use translation here
      setIsLoading(false);
      return;
    }

    if (!emailRegex.test(email)) {
      toast.info(t('error_invalid_email')); // Use translation here
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const searchUserEmail = query(
      collection(db, "users"),
      where("email", "==", email)
    );

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(t('success_reset_email_sent')); // Use translation here
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        toast.error(t('error_user_not_found')); // Use translation here
        setIsLoading(false);
        return;
      }
      toast.error(t('error_generic')); // Use translation here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <HeaderAuth transparent />
      <form className="mt-[5vh] mx-auto w-full p-5 bg-white max-w-xs md:max-w-md rounded-sm border border-gray-300">
        <h2 className="font-semibold text-2xl">{t('forgot_password_title')}</h2>
        <h3 className="mb-4 text-base text-gray-500">
          {t('forgot_password_instruction')}
        </h3>
        <div className="mt-7 mb-3">
          <Input
            className="w-full"
            placeholder={t('email_placeholder')}
            value={email}
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="mt-4">
          <Button
            style={{ width: "100%" }}
            onClick={validate}
            loading={isLoading}
            variant="solid"
          >
            {t('send_link_button')}
          </Button>
        </div>
      </form>
    </div>
  );
}
