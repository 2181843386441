import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import { toast } from "react-toastify";
import { Button, Input } from "@mui/joy";
import { auth, db } from "../config/firebase";
import PageTemplate from "../templates/PageTemplate";
import { removeSpaces } from "../utils/allFunctions";

export default function MyRIB() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [iban, setIban] = useState(data?.bankInfo?.iban);
  const [bic, setBic] = useState(data?.bankInfo?.bic);
  const [bankName, setBankName] = useState(data?.bankInfo?.bankName);
  const [buttonisLoading, setButtonIsLoading] = useState(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const docRef = doc(db, "users", auth?.currentUser?.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = { id: docSnap.id, ...docSnap.data() };
        setData(userData);
        setIban(userData?.bankInfo?.iban);
        setBic(userData?.bankInfo?.bic);
        setBankName(userData?.bankInfo?.bankName);
      }
    } catch (error) {
      alert(
        `Nous n'arrivons pas à récuperer vos données, veuillez contacter le support: ${error}`
      );
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const validate = async () => {
    setButtonIsLoading(true);
    try {
      // Update the user document in Firestore
      await updateDoc(doc(db, "users", auth.currentUser?.uid), {
        bankInfo: {
          iban: removeSpaces(iban.toUpperCase()),
          bic: bic.toUpperCase(),
          bankName,
        },
      });

      toast.success("Vos informations bancaires ont été modifiées avec succès");
      getUserInfo();
    } catch (error) {
      toast.error(`Erreur lors de la mise à jour: ${error.message}`);
    } finally {
      setButtonIsLoading(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <PageTemplate
      title={"Informations bancaires"}
      content={
        <form className="p-5 bg-gray-200 max-w-md rounded-sm border border-gray-300">
          <div className="mt-2">
            <Input
              className="w-full"
              value={iban}
              variant="outlined"
              placeholder="Votre IBAN *"
              onChange={(e) => {
                setIban(e.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <Input
              className="w-full"
              value={bic}
              variant="outlined"
              placeholder="Votre BIC *"
              onChange={(e) => {
                setBic(e.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <Input
              className="w-full"
              value={bankName}
              variant="outlined"
              placeholder="Nom de votre banque *"
              onChange={(e) => {
                setBankName(e.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <Button
              style={{ width: "100%" }}
              onClick={validate}
              loading={buttonisLoading}
              variant="solid"
            >
              Valider
            </Button>
          </div>
        </form>
      }
    />
  );
}
