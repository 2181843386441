import React from "react";
import { useTranslation } from "react-i18next";
import internet from "../assets/img/internet.png";
import HeaderHome from "../components/Headers/HeaderHome";
import Footer from "../components/Footer";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
   <>
    <HeaderHome transparent />
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <img
          src={internet}
          alt="404 Not Found"
          className="w-2/3 lg:w-1/2 mx-auto mb-8"
        />
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          {t("pageNotFound")}
        </h1>
        <p className="text-gray-600 mb-8">{t("message")}</p>
        <a
          href="/"
          className="px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
        >
          {t("goHome")}
        </a>
      </div>
    </div>
    <Footer />
   </>
  );
};

export default NotFoundPage;
