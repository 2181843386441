import React from "react";
import Select from "react-select";
import { COUNTRIES } from "../utils/countries";

const CountrySelect = ({onChange}) => {
  const options = COUNTRIES.map((country) => ({
    value: country.code,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={country.flagUrl}
          alt={country.name}
          style={{ width: "20px", marginRight: "8px" }}
        />
        {country.name}
      </div>
    ),
  }));

  return <Select onChange={onChange} options={options} />;
};

export default CountrySelect;
