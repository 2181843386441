import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar as JoyAvatar,
  Button as JoyButton,
  Drawer as JoyDrawer,
  IconButton as JoyIconButton,
} from "@mui/joy";
import MenuIcon from "@mui/icons-material/Menu";
import loginImg from "../../assets/icon.png";
import frImg from "../../assets/img/fr.png";
import enImg from "../../assets/img/en.png";
import { useTranslation } from "react-i18next";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";

const Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const logOut = async () => {
    await signOut(auth);
    navigate("/");
  };

  const menuItems = [
    { text: t("menu_home"), path: "/" },
    { text: t("menu_my_searches"), path: "/mes-recherches" },
    { text: t("menu_my_findings"), path: "/mes-trouvailles" },
    { text: t("menu_notifications"), path: "/notifications" },
  ];

  return (
    <header className="p-3 mx-auto w-11/12 md:w-4/6 bg-white border-b border-gray-300 w-full flex flex-row items-center justify-between rounded-b-lg">
      <div className="py-3 cursor-pointer flex flex-row items-center">
        <img
          onClick={() => navigate("/")}
          src={loginImg}
          className="w-14 h-14 md:w-20 md:h-20 rounded-full"
          alt="Logo"
        />
        <div
          onClick={() =>
            handleChangeLanguage(i18n?.language == "en" ? "fr" : "en")
          }
          className="flex flex-row items-center"
        >
          <img
            src={i18n?.language == "en" ? enImg : frImg}
            className="w-7 h-5 ml-3"
            alt="flag"
          />
          <i class="fa-solid fa-chevron-down text-base text-gray-500 ml-1"></i>
        </div>
      </div>

      {/* Mobile View - Hamburger Menu */}
      <div className="md:hidden">
        <JoyIconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </JoyIconButton>
        <JoyDrawer anchor="left" open={open} onClose={handleDrawerToggle}>
          <div className="p-4">
            {menuItems.map((item, index) => (
              <JoyButton
                key={index}
                onClick={() => {
                  navigate(item.path);
                  handleDrawerToggle();
                }}
                variant="plain"
                className="w-full"
              >
                {item.text}
              </JoyButton>
            ))}
            <JoyButton
              onClick={() => navigate("/publier-une-recherche")}
              variant="solid"
              className="w-full mt-4"
            >
              Publier
            </JoyButton>
            <div className="flex flex-row items-center mt-4">
              <JoyAvatar
                onClick={() => navigate("/mon-profil")}
                sx={{ bgcolor: "#d35400", color: "white" }}
              >
                {auth?.currentUser?.displayName.charAt(0)}
              </JoyAvatar>
              <i
                onClick={logOut}
                className="cursor-pointer fa-solid fa-power-off text-xl text-gray-500 ml-2"
              ></i>
            </div>
          </div>
        </JoyDrawer>
      </div>

      {/* Desktop View - Navbar */}
      <nav className="items-center hidden md:flex md:flex-row">
        {menuItems.map((item, index) => (
          <div key={index} className="mr-4">
            <JoyButton onClick={() => navigate(item.path)} variant="plain">
              {item.text}
            </JoyButton>
          </div>
        ))}
        <div className="ml-4">
          <JoyButton
            onClick={() => navigate("/publier-une-recherche")}
            variant="solid"
          >
            {t("menu_publier")}
          </JoyButton>
        </div>
        <div className="cursor-pointer ml-10 flex flex-row items-center">
          <JoyAvatar
            onClick={() => navigate("/mon-profil")}
            sx={{ bgcolor: "#d35400", color: "white" }}
          >
            {auth?.currentUser?.displayName.charAt(0)}
          </JoyAvatar>
          <i
            onClick={logOut}
            className="cursor-pointer fa-solid fa-power-off text-xl text-gray-500 ml-2"
          ></i>
        </div>
      </nav>
    </header>
  );
};

export default Header;
