import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../templates/PageTemplate";
import { auth, db } from "../config/firebase";
import { Button } from "@mui/joy";
import { useTranslation } from 'react-i18next';

export default function MyFinds({ navigation }) {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook for translations
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSearchs();
  }, []);

  async function getSearchs() {
    const q = query(
      collection(db, "searchs"),
      where("finderId", "==", auth?.currentUser?.uid)
    );

    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      dataArr.push({
        id: res.id,
        ...res.data()
      });
      setData(dataArr);
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }


  if (isLoading) {
    return null;
  }

  const Card = ({ key, item }) => (
    <div
      key={key}
      activeOpacity={0.7}
      className="cursor-pointer w-full px-4 py-2 mb-2 rounded-md bg-white border border-gray-100"
    >
      <h3 className="text-base text-black uppercase">
        {item.plate}
        {item.finderPaymentStatus === "paid" && (
          <span className="ml-2 text-green-500">{t('payment_status_paid')}</span>
        )}
        {item.finderPaymentStatus !== "paid" && (
          <span className="ml-2 text-blue-500">
            {t('payment_status_unpaid')}
          </span>
        )}
      </h3>
      {item?.paymentStatus === "paid" && (
        <h3 className="text-base text-green-500 mb-2 uppercase">
          {t('position_label')}
          {`${item?.address.street + " · " + item?.address.city + " " + item?.address.postalCode + ", " + item?.address.country}`}
        </h3>
      )}

      <h4 className="text-xs mt-4 self-end text-gray-400">
        {`${t('found_on')} ${moment(item.findedAt).format(
          "DD/MM/YYYY à h:mm a"
        )}`}
      </h4>
      <h4 className="mt-1 text-xs self-end text-gray-400">
        {`${t('created_on')} ${moment(item.createdAt).format("DD/MM/YYYY à h:mm a")}`}
      </h4>
    </div>
  );

  if (data.length < 1) {
    return (
      <PageTemplate
        title={t('my_finds_title')}
        content={
          <div className="flex flex-col items-center justify-center">
            <img
              src={require("../assets/img/x.png")}
              alt={t('no_vehicle_found')} // Ajoutez alt pour l'accessibilité
              className="w-24 h-24"
            />
            <span className="text-base text-center text-black mt-10 mb-2">
              {t('no_vehicle_found')}
            </span>
            <span className="text-sm text-center text-gray-700">
              {t('no_vehicle_found_description')}
            </span>
          </div>
        }
      />
    );
  }
  return (
    <PageTemplate
      title={t('my_finds_title')}
      content={
        <div className="">
          <div className="mb-5 flex flex-row pt-2 pb-4 border border-blue-100 p-2 rounded-md">
            <i className="fa-solid fa-circle-exclamation text-blue-500"></i>
            <div>
              <p className="text-base text-blue-500 ml-2">
                {t('payment_instructions')}
              </p>
            </div>
          </div>
          {data.map((item, i) => (
            <Card key={i} item={item} />
          ))}
        </div>
      }
    />
  );
}
