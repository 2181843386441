import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import Input from "@mui/joy/Input";
import { Button } from "@mui/joy";
import HeaderAuth from "../../components/Headers/HeaderAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook for translations
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = async () => {
    if (!email || !password) {
      toast.info(t("error_empty_fields")); // Use translation here
      return;
    }

    setIsLoading(true);
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(t("error_generic"));
      });
  };

  return (
    <div className="loginBg bg-gray-200 min-h-screen">
      <HeaderAuth transparent />
      <form className="mt-[5vh] mx-auto w-full p-5 bg-white max-w-xs md:max-w-md rounded-sm border border-gray-300">
        <h2 className="font-semibold text-2xl">{t("welcome")}</h2>
        <h3 className="mb-4 text-base text-gray-500">{t("login_prompt")}</h3>
        <div className="mt-7 mb-3">
          <Input
            className="w-full"
            placeholder={t("email_placeholder")}
            value={email}
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <Input
          className="w-full"
          placeholder={t("password_placeholder")}
          value={password}
          variant="outlined"
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <div className="mt-4 flex justify-end">
          <Button
            onClick={() => navigate("/mot-de-passe-oublié")}
            variant="plain"
          >
            {t("forgot_password")}
          </Button>
        </div>
        <div className="mt-4">
          <Button
            style={{ width: "100%" }}
            onClick={login}
            loading={isLoading}
            variant="solid"
          >
            {t("login_button")}
          </Button>
        </div>
        <div className="mt-2 w-full">
          <Button
            style={{ width: "100%" }}
            onClick={() => navigate("/inscription")}
            variant="outlined"
          >
            {t("create_account")}
          </Button>
        </div>
      </form>
    </div>
  );
}
