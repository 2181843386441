import React from "react";
import loginImg from "../../assets/icon.png";
import { Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import frImg from "../../assets/img/fr.png";
import enImg from "../../assets/img/en.png";
import { useTranslation } from "react-i18next";




export default function HeaderAuth({ transparent }) {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };


  return (
    <div
      className={
        transparent
          ? "p-3 mx-auto w-11/12 md:w-4/6 bg-transparent border-b border-transparent w-full flex flex-row items-center justify-between rounded-b-lg"
          : "p-3 mx-auto w-11/12 md:w-4/6 bg-gray-200 border-b border-gray-300 w-full flex flex-row items-center justify-between rounded-b-lg"
      }
    >
       <div className="py-3 cursor-pointer flex flex-row items-center">
        <img
          onClick={() => navigate("/")}
          src={loginImg}
          className="w-14 h-14 md:w-20 md:h-20 rounded-full"
          alt="Logo"
        />
        <div
          onClick={() =>
            handleChangeLanguage(i18n?.language == "en" ? "fr" : "en")
          }
          className="flex flex-row items-center"
        >
          <img
            src={i18n?.language == "en" ? enImg : frImg}
            className="w-7 h-5 ml-3"
            alt="flag"
          />
          <i class="fa-solid fa-chevron-down text-base text-gray-500 ml-1"></i>
        </div>
      </div>
      <div className="">
        <Button size="lg" onClick={() => navigate("/mentions-legales")} variant={transparent?"solid":"plain"}>
            {t("menu_legal")}
        </Button>
      </div>
    </div>
  );
}
