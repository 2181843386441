import "react-toastify/dist/ReactToastify.css";
import React, {  useState } from "react";
import PageTemplate from "../templates/PageTemplate";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { Button } from "@mui/joy";
import moment from "moment";
import { toast } from "react-toastify";
import Textarea from "@mui/joy/Textarea";

export default function WriteComment() {
  const [buttonisLoading, setButtonIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [desc, setDesc] = useState("");

  const validate = async () => {
    if (!desc) {
      toast.info(`Des champs sont manquants`);
      setButtonIsLoading(false);
      return;
    }

    setButtonIsLoading(true);

    try {
      await addDoc(collection(db, "comments"), {
        comment: desc,
        userId: auth?.currentUser ? auth.currentUser.uid : null,
        createdAt: moment().format(),
      });

      toast.success(
        "Votre avis compte énormément pour nous, merci de contribuer à rendre notre application encore plus géniale!"
      );
      setDesc("");
    } catch (e) {
      toast.error(`Erreur d'envoi du commentaire: ${e}`);
    } finally {
      setButtonIsLoading(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <PageTemplate
      title={"Laisser un commentaire"}
      content={
        <form className="p-5 bg-gray-200 max-w-md rounded-sm border border-gray-300 ">
          <Textarea
            className="w-full"
            value={desc}
            variant="outlined"
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Écrivez votre commentaire ici..."
            style={{ display: "block", height: 250 }}
          />
          <div className="mt-4">
            <Button  style={{ width: "100%" }}
              onClick={validate}
              loading={buttonisLoading}
              variant="solid"
            >
              Envoyer
            </Button>
          </div>
        </form>
      }
    />
  );
}
