import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  query,
  where,
  updateDoc,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { auth, db, storage } from "../config/firebase";
import tropheeImg from "../assets/img/trophee.png";
import { Button, Input } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageTemplate from "../templates/PageTemplate";
import { toast } from "react-toastify";

const FindACar = () => {
  const [street, setStreet] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [coords, setCoords] = useState(null);
  const [country, setCountry] = useState("");
  const [check, setCheck] = useState(false);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGetLocation = async () => {
    if (!navigator.geolocation) {
      toast.info(t("error_geolocation"));
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setCoords({ lat: latitude, long: longitude });

        try {
          const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_GEOCODING; // Remplacez par votre clé API
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
          );

          if (response.data.status === "OK") {
            const addressComponents = response.data.results[0].address_components;

            const street = addressComponents.find((component) =>
              component.types.includes("route")
            )?.long_name || "";
            const postalCode = addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
            const city = addressComponents.find((component) =>
              component.types.includes("locality")
            )?.long_name || "";
            const country = addressComponents.find((component) =>
              component.types.includes("country")
            )?.long_name || "";

            setStreet(street);
            setPostalCode(postalCode);
            setCity(city);
            setCountry(country);
          } else {
            toast.info(t("error_no_address"));
          }
        } catch (error) {
          console.error("Erreur lors de la récupération de l'adresse :", error);
          toast.info(t("error_fetch_address"));
        }
      },
      (error) => {
        console.error("Erreur de géolocalisation :", error);
        toast.info(t("error_geolocation"));
      }
    );
  };

  const handleImageUpload = async (image) => {
    try {
      const response = await fetch(image);
      const blob = await response.blob();
      const storageRef = ref(
        storage,
        `findedVehiclesPhotos/${licensePlate.toUpperCase()}_${Date.now()}`
      );
      const snapshot = await uploadBytesResumable(storageRef, blob);
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length > 8) {
      toast.info(t("error_max_photos"));
      return;
    }
    const newImages = files.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleValidate = async () => {
    setIsLoading(true);
    if (
      !licensePlate ||
      !street ||
      !city ||
      !country ||
      !postalCode
    ) {
      toast.info(t("error_empty_fields"));
      setIsLoading(false);
      return;
    }

    if (!images.length) {
      toast.info(t("error_no_photos"));
      setIsLoading(false);
      return;
    }

    if (images.length >= 8) {
      toast.info(t("error_max_photos"));
      setIsLoading(false);
      return;
    }

    try {
      const q = query(
        collection(db, "searchs"),
        where("plate", "==", licensePlate.toUpperCase()),
        where("finderId", "==", null),
        where("status", "==", "progress")
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const findedSearchDemand = querySnapshot.docs[0].data();
        const docSnap = await getDoc(
          doc(db, "users", findedSearchDemand.userId)
        );

        if (docSnap.exists()) {
          const imageLinks = await Promise.all(images.map(handleImageUpload));
          await updateDoc(doc(db, "searchs", findedSearchDemand.id), {
            address: { street, city, country, postalCode, coords },
            photos: imageLinks,
            status: "ended",
            paymentStatus: "unpaid",
            finderId: auth.currentUser.uid,
            finderPaymentStatus: "unpaid",
            findedAt: moment().format(),
          });
          setCheck(true);
        }
      } else {
        await addDoc(collection(db, "searchs"), {
          addedViaFindScreen: true,
          plate: licensePlate.toUpperCase(),
          userId: null,
          finderId: auth.currentUser.uid,
          status: "progress",
          address: { street, city, country, postalCode, coords },
          photos: await Promise.all(images.map(handleImageUpload)),
          paymentStatus: "unpaid",
          finderPaymentStatus: "unpaid",
          findedAt: moment().format(),
        });
        toast.success(t("submission_success"));
        setIsLoading(false);
        setCheck(true);
      }
    } catch (error) {
      toast.info(t("submission_error"));
    } finally {
      setIsLoading(false);
    }
  };

  if (!check) {
    return (
      <PageTemplate
        title={""}
        content={
          <div className="flex flex-col items-center justify-center py-20 px-5 md:px-10 max-w-lg mx-auto">
            <img
              src={tropheeImg}
              alt="Trophée"
              style={{ width: 96, height: 96 }}
            />
            <div className="mb-4">
              <h2 className="mt-4 mb-2 text-2xl text-center">
                {t("submission_success")}
              </h2>
              <p className="text-center text-base">
                {t("submission_info")}
              </p>
            </div>
            <Button
              onClick={() => navigate("/dashboard")}
              loading={isLoading}
              variant="solid"
            >
              {t("back_to_home")}
            </Button>
          </div>
        }
      />
    );
  }

  return (
    <PageTemplate
      title={t("find_vehicle")}
      content={
        <form className="p-5 bg-gray-200 max-w-md rounded-sm border border-gray-300">
          <h2 className="text-base">{t("photos")}</h2>

          <input
            type="file"
            className="w-full"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            variant="outlined"
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
            {images.map((item, index) => (
              <div
                className="bg-white"
                key={index}
                style={{ width: "48%", position: "relative" }}
              >
                <img
                  src={item}
                  alt={`Uploaded`}
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "8px",
                    border: "1px solid gray",
                    objectFit: "contain",
                  }}
                />
                <button
                  onClick={() =>
                    setImages(images.filter((_, i) => i !== index))
                  }
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    borderRadius: "50%",
                    width: "28px",
                    height: "28px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                  }}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          <h2 className="mt-7 text-base">{t("license_plate")}</h2>
          <Input
            className="w-full"
            placeholder={t("license_plate")}
            variant="outlined"
            value={licensePlate}
            onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
          />
          <h2 className="mt-3 text-base">{t("address")}</h2>
          <Button
            style={{ width: "100%" }}
            onClick={handleGetLocation}
            loading={isLoading}
            variant="solid"
          >
            {t("address_auto_fill")}
          </Button>

          <div className="mt-2">
            <span className="text-base">{t("street_number")}</span>
            <Input
              className="w-full"
              placeholder={t("street_number")}
              variant="outlined"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
            />
          </div>

          <div className="mt-2">
            <span className="text-base">{t("postal_code")}</span>
            <Input
              type="text"
              placeholder={t("postal_code")}
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>

          <div className="mt-2">
            <span className="text-base">{t("city")}</span>
            <Input
              type="text"
              placeholder={t("city")}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className="mt-2 mb-10">
            <span className="text-base">{t("country")}</span>
            <Input
              type="text"
              placeholder={t("country")}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>

          {coords && (
            <MapContainer
              center={[coords.lat, coords.long]}
              zoom={13}
              style={{ height: "300px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[coords.lat, coords.long]} />
              <Circle center={[coords.lat, coords.long]} radius={500} />
            </MapContainer>
          )}

          <div className="mt-3">
          <Button
            style={{ width: "100%" }}
            onClick={handleValidate}
            loading={isLoading}
            variant="solid"
          >
            {t("validate_button")}
          </Button>
          </div>
        </form>
      }
    />
  );
};

export default FindACar;
