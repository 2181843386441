import React from "react";
import { Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <footer>
      <div className="homeBanner rounded-sm flex flex-col items-center justify-between w-full p-10">
        <h2 className="text-white text-xl text-center">
          {t("footer_download_app")}
        </h2>
        <a
          href="https://play.google.com/store/apps/details?id=com.sur4plots"
          target="_blank"
          rel="noopener noreferrer" // Ajoutez rel="noopener noreferrer" pour des raisons de sécurité
          className="py-3 cursor-pointer"
        >
          <img
            src={require("../assets/img/playStore.png")}
            className="w-40 h-12"
            alt={t("footer_google_play")} // Ajoutez alt pour l'accessibilité
          />
        </a>
      </div>
      <div className="self-center mx-auto py-5 w-11/12 md:w-4/6 bg-transparent border-t border-gray-300 w-full flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="">
          <Button onClick={() => navigate("/")} variant="plain">
            {t("footer_home")}
          </Button>
        </div>

        <div className="">
          <Button
            onClick={() => navigate("/comment-ca-marche")}
            variant="plain"
          >
            {t("footer_how_it_works")}
          </Button>
        </div>

        <div className="">
          <Button
            onClick={() => navigate("/politique-de-confidentialite")}
            variant="plain"
          >
            {t("footer_privacy_policy")}
          </Button>
        </div>

      

        <div className="">
          <Button onClick={() => navigate("/mentions-legales")} variant="plain">
            {t("footer_legal_mentions")}
          </Button>
        </div>

       

        <div className="">
          <Button onClick={() => navigate("/contact")} variant="plain">
            {t("footer_contact")}
          </Button>
        </div>
      </div>
    </footer>
  );
}
