import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../templates/PageTemplate";
import { auth, db } from "../config/firebase";
import { Button } from "@mui/joy";
import qs from "qs";
import axios from "axios";
import { toast } from "react-toastify";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";

export default function MySearchs({ navigation }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    getSearchs();
  }, []);

  async function getSearchs() {
    const q = query(
      collection(db, "searchs"),
      where("userId", "==", auth?.currentUser?.uid)
    );

    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      const otherData = await getMoreInfo(res.data().type);
      dataArr.push({
        id: res.id,
        ...res.data(),
        price: otherData[0].price,
        image: otherData[0].image,
      });
      setData(dataArr);
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  async function getMoreInfo(type) {
    const q = query(collection(db, "categories"), where("name", "==", type));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      dataArr.push({ id: res.id, ...res.data() });
    });
    return dataArr;
  }

  if (isLoading) {
    return null;
  }

  const Card = ({ key, item }) => {
    const handleApprove = async (orderID) => {
      toast.success("Payment successful!");
      const docRef = doc(db, "searchs", item.id);
      await updateDoc(docRef, {
        paymentStatus: "paid",
        paymentMethod: "PayPal",
        price: String(item.price),
        paiedAt: moment().format(),
      });
      navigate("/paiement-validé");
      // window.open("https://sur4plots.com/success_payments", "_blank");
    };

    return (
      <div
        key={key}
        activeOpacity={0.7}
        className="cursor-pointer w-full px-4 py-2 mb-2 rounded-md bg-white border border-gray-300"
      >
        <h3 className="text-base text-black uppercase">
          {item.plate} · {item.type}
          <span className="ml-2 text-green-500">
            {item.paymentStatus === "paid" && ` · ${t("paid_status")}`}
          </span>
        </h3>
        {item?.paymentStatus === "paid" && (
          <h3 className="text-base text-green-500 mb-2 uppercase">
            {t("position")}
            {`${
              item?.address.street +
              " · " +
              item?.address.city +
              " " +
              item?.address.postalCode +
              ", " +
              item?.address.country
            }`}
          </h3>
        )}

        <h4 className="text-xs mt-4 self-end text-gray-400">
          {item.status === "progress"
            ? t("status_in_progress")
            : `${t("status_found_on")} ${moment(item.findedAt).format(
                "DD/MM/YYYY à h:mm a"
              )}`}
        </h4>
        <h4 className="mt-1 text-xs self-end text-gray-400">
          {`${t("created_on")} ${moment(item.createdAt).format(
            "DD/MM/YYYY à h:mm a"
          )}`}
        </h4>

        <>
          {item?.status === "ended" && item?.paymentStatus !== "paid" && (
            <div className="mt-3">
              <div className="mt-3 flex items-center space-x-2">
                <span className="text-lg font-bold">{item.price} €</span>
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value: item.price.toString(),
                          },
                        },
                      ],
                    });
                  }}
                  onApprove={async (data, actions) => {
                    return actions.order.capture().then(async (details) => {
                      await handleApprove(details.id);
                    });
                  }}
                  onError={(err) => {
                    console.error("PayPal Checkout onError", err);
                    toast.error(t("paypal_error"));
                  }}
                />
              </div>
            </div>
          )}
        </>

        {item?.status === "ended" && (
          <div className="mt-4">
            <Button
              onClick={() => window.open(item.photos[0], "_blank")}
              loading={isLoading}
              variant="outlined"
            >
              {t("view_photo")}{" "}
              {item?.paymentStatus !== "paid" && t("view_photo_free")}
            </Button>
          </div>
        )}
      </div>
    );
  };

  if (data.length < 1) {
    return (
      <PageTemplate
        title={t("page_title_my_searches")}
        content={
          <div className="flex flex-col items-center justify-center">
            <img
              src={require("../assets/img/x.png")}
              alt={t("no_requests")} // Ajoutez alt pour l'accessibilité
              className="w-24 h-24"
            />
            <span className="text-base text-center text-black mt-10 mb-2">
              {t("no_requests")}
            </span>
            <span className="text-sm text-center text-gray-700">
              {t("no_requests_description")}
            </span>
          </div>
        }
      />
    );
  }

  return (
    <PageTemplate
      title={t("page_title_my_searches")}
      content={
        <div className="">
          {data.map((item, i) => (
            <Card key={i} item={item} />
          ))}
        </div>
      }
    />
  );
}
